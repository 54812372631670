

const {
  REACT_APP_GESLUB_API_REST = "https://geslub-api-sandbox-2licfeyhca-tl.a.run.app",
  SENTRY_DSN = "https://210a938c13c346cab205005bd251df2f@o1249435.ingest.sentry.io/4504720066805760",
} = process.env;


export const API = {
  GESLUB: REACT_APP_GESLUB_API_REST + "/graphql",
  GESLUB_REST: REACT_APP_GESLUB_API_REST,
};

export const SENTRY = {
  DSN: SENTRY_DSN,
};

export const GESLUB_PLATFORM_URLS = {
  PLANNING: "https://planning.geslub.com",
  LIBRARY: "https://library.geslub.com",
  TRIBOLAB: "https://tribolab.geslub.cl",
  STOCKS: "https://vales.geslub.cl",
  HOME: "http://localhost:3300/",
};
