import { useErrorLogger } from "../../utils/error/logError";
import style from "./users.module.css";
import { ApolloError } from "@apollo/client";
import { Modal, Icon } from "@belray-ventec/geslub-platform-react-ui";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import UserForm, { UserFormProps } from "core/users/forms/UserForm";
import { useUserList } from "core/users/hooks";
import { User } from "core/users/types";
import { useZoneList } from "core/zones/hooks";
import { useEffect, useState } from "react";
import Select from "react-select";
import AdminTable, { AdminTableProps } from "ui/tables";
import { getErrors } from "utils/apollo/handlerErrors";
import removeAccents from "utils/function/removeAccent";
import { useShowToast } from "utils/hooks/useShowToast";

interface zoneOptionsType {
  label: string;
  value: string;
}

export default function Users() {
  const logError = useErrorLogger();
  const [createCompanyModal, setCreateUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState<User | null>(null);
  const { UserList, onCreateUser, onUpdateUser, onRemoveUser } = useUserList();
  const { zoneList, isLoadingZoneList } = useZoneList();
  const [zoneOptions, setZoneOptions] = useState<zoneOptionsType[]>();
  const { showToast } = useShowToast();
  const [zoneFilter, setZoneFilter] = useState<zoneOptionsType[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");

  useEffect(() => {
    !isLoadingZoneList &&
      setZoneOptions(
        zoneList.map((zone) => ({ label: zone.name, value: zone.id }))
      );
  }, [isLoadingZoneList]);

  const handleCreateUser: UserFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      await onCreateUser(data);
      stopLoading();
      setCreateUserModal(false);
      showToast(
        "Usuario creado",
        "El usuario ha sido creado correctamente",
        "success"
      );
    } catch (e) {
      if (e instanceof ApolloError) {
        const errors = getErrors(e);
        logError(e);
        showToast(
          "No se ha podido crear el usuario",
          `${errors.map((error) => <p key={error}>{error}</p>)}`,
          "error"
        );
        stopLoading();
      }
    }
  };

  const handleEditUser: UserFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      if (editUserModal)
        await onUpdateUser(editUserModal.id, {
          ...data,
          zones: data.zones.map((item) => item.id),
        });
      stopLoading();
      setEditUserModal(null);
      showToast(
        "Usuario editado",
        "El usuario ha sido editado correctamente",
        "success"
      );
    } catch (e) {
      const errors = getErrors(e);
      logError(e);
      showToast(
        "No se ha podido editar el usuario",
        `${errors.map((error) => <p key={error}>{error}</p>)}`,
        "error"
      );
      stopLoading();
    }
  };

  const userIncludeZone = (
    zones: User["zones"],
    selectedZones: zoneOptionsType[]
  ): boolean => {
    let existZone = false;
    selectedZones.forEach((z) => {
      if (zones.find((zone) => zone.id === z.value)) existZone = true;
    });
    return existZone;
  };

  const filterByZone = (selectedZones: zoneOptionsType[]): User[] => {
    if (selectedZones.length > 0)
      return UserList.filter((user) =>
        userIncludeZone(user.zones, selectedZones)
      );
    return UserList;
  };

  const filterUsersByProps = (value: string): User[] => {
    return UserList.filter(
      (user) =>
        removeAccents(user.name.toLocaleLowerCase()).includes(
          removeAccents(value)
        ) ||
        removeAccents(user.lastname.toLocaleLowerCase()).includes(
          removeAccents(value)
        ) ||
        removeAccents(user.email.toLocaleLowerCase()).includes(
          removeAccents(value)
        ) ||
        removeAccents(user.phone.toLocaleLowerCase()).includes(
          removeAccents(value)
        ) ||
        removeAccents(user.rut.toLowerCase()).includes(removeAccents(value))
    );
  };

  const handleDisable: AdminTableProps<User>["onDisable"] = async (
    data,
    stopLoading
  ) => {
    try {
      await onUpdateUser(data.id, { enabled: false });
      showToast(
        "Usuario deshabilitado",
        "El usuario ha sido deshabilitado correctamente",
        "success"
      );
    } catch (e) {
      logError(e);
      showToast("Error", "No se ha podido deshabilitar el usuario", "error");
    } finally {
      stopLoading();
    }
  };

  const handleRemoveUser: AdminTableProps<User>["onRemove"] = async (
    data,
    stopLoading
  ) => {
    try {
      await onRemoveUser(data.id);
      showToast(
        "Usuario eliminado",
        "El usuario ha sido eliminado correctamente",
        "success"
      );
    } catch (e) {
      logError(e);
      showToast("Error", "No se ha podido eliminar el usuario", "error");
    } finally {
      stopLoading();
    }
  };

  const handleEnable: AdminTableProps<User>["onEnable"] = async (data) => {
    try {
      await onUpdateUser(data.id, { enabled: true });
      showToast(
        "Usuario habilitado",
        "El usuario ha sido habilitado correctamente",
        "success"
      );
    } catch (e) {
      logError(e);
      showToast("Error", "No se ha podido habilitar el usuario", "error");
    }
  };

  const filteredUsers = filterByZone(zoneFilter).filter((user) =>
    filterUsersByProps(searchFilter).includes(user)
  );

  return (
    <>
      <Box>
        <Box className={style.header_users_page}>
          <Button
            colorScheme="blue"
            onClick={() => setCreateUserModal(true)}
            leftIcon={<Icon color="#fff" size={15} icon="Add" />}
          >
            Crear usuario
          </Button>
          <Box className={style.headers_users_page__left_content}>
            <Box flex="1">
              <Select
                isMulti
                options={zoneOptions}
                placeholder="Zonas"
                value={zoneFilter}
                onChange={(elements) =>
                  setZoneFilter(elements as zoneOptionsType[])
                }
              />
            </Box>
            <Box flex="1">
              <InputGroup>
                <InputLeftElement>
                  <Icon size={16} color="#a9a9a9" icon="Seeker" />
                </InputLeftElement>
                <Input
                  value={searchFilter}
                  placeholder="Ingrese nombre, email..."
                  onChange={(e) =>
                    setSearchFilter(e.target.value.trim().toLocaleLowerCase())
                  }
                />
              </InputGroup>
            </Box>
          </Box>
        </Box>

        <AdminTable
          data={filteredUsers}
          columns={[
            { label: "Rut", getValue: (user) => user.rut },
            { label: "Nombre", getValue: (user) => user.name },
            { label: "Apellido", getValue: (user) => user.lastname },
            { label: "Correo", getValue: (user) => user.email },
            {
              label: "Planning",
              getValue: (user) => user.planningConfig.role.label,
            },
            {
              label: "Library",
              getValue: (user) => user.libraryConfig.role.label,
            },
          ]}
          getRowKey={(item) => item.id}
          onEdit={(item) => setEditUserModal(item)}
          caption="Lista de usuarios"
          onEnable={handleEnable}
          onDisable={handleDisable}
          getIfRowIsEnabled={(item) => item.enabled}
          onRemove={handleRemoveUser}
        />
      </Box>

      <Modal
        title={"Crear usuario"}
        isOpen={createCompanyModal}
        onClose={() => setCreateUserModal(false)}
        hiddenFooter
      >
        <UserForm onSubmit={handleCreateUser} />
      </Modal>
      {editUserModal && (
        <Modal
          title={"Editar usuario"}
          isOpen={Boolean(editUserModal)}
          onClose={() => setEditUserModal(null)}
          hiddenFooter
        >
          <UserForm onSubmit={handleEditUser} initValues={editUserModal} />
        </Modal>
      )}
    </>
  );
}
